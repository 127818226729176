<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">
      <img class="dqi-icon" src="@/assets/image/danghui.png" alt="" />东泰社区党委数据
    </div>
    <div class="idx-middle-2" id="dgwData" @click="go1"></div>
    <!--  -->
    <div class="idx-middle-3" @click="go">
      <span>{{ getdatavalue(96) }}</span>
      <span>已退休人数</span>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "dwjs",
        dataTyp: "dwjs_dgwsjtx",
      },
      queryParams1: {
        dataModule: "dwjs",
        dataTyp: "dwjs_dgwsj",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);
      this.dataOptions1 = response.rows
      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "已退休人数", arr);
    },
    go1() {
      let arr = this.dataOptions1.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "东泰社区党委数据", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = ["#2363F1", "#AF36DC", "#1F933A"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "horizontal",
            left: "0%",
            top: "80%",
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 10,
              padding: 0,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata, //["东泰社区两新党委", "东泰社区政法党支部", "东泰社区党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "25%",
              center: ["45%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = ["#2363F1", "#AF36DC", "#1F933A"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "horizontal",
            left: "2%",
            top: "80%",
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 8,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata, //["东泰社区两新党委", "东泰社区政法党支部", "东泰社区党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "30%",
              center: ["45%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 51vh;
  overflow: hidden;
  position: relative;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    .dqi-icon {
      vertical-align: middle;
      padding-right: 1vw;
    }
  }
  .idx-middle-2 {
    width: 100%;
    height: 39vh;
    // border: 1px solid tan;
  }
  .idx-middle-3 {
    width: 23%;
    height: 8vh;
    line-height: 3vh;
    background: #01689b;
    position: absolute;
    top: 20vh;
    right: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    span {
      font-size: 0.9vw;
      &:first-child {
        font-size: 1.2vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
      }
    }
  }
}
</style>
