<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">
      <img class="dqi-icon" src="@/assets/image/danghui.png" alt="" />党务建设
    </div>
    <div @click="go">
      <div class="idx-middle-2">
        <div class="idx-Lbox-2">
          <img src="@/assets/image/idx-icon-yh.png" />
          <span class="idx-dy-sa1"> 党员 </span>
          <span class="idx-dy-s1"> {{ getdatavalue(86) }} </span>名
          <!-- 党员名册 -->
          <span class="dwjs-btn">
            <img class="dwjs-btn-1" src="@/assets/image/dwjs-mce.png" />
            <img class="dwjs-btn-2" src="@/assets/image/dwjs-name.png" />
            <router-link tag="span" class="dwjs-btn-3" to="/memberRegister"
              >党员名册</router-link
            >
            <!-- <span class="dwjs-btn-3">党员名册1</span> -->
          </span>
          <span class="idx-dy-s3"><div class="gl-sss"></div></span>
          <img src="@/assets/image/idx-icon-dy3.png" />
          <span class="idx-dy-sa1"> 组织 </span>
          <span class="idx-dy-s2"> {{ getdatavalue(87) }} </span>个
        </div>
      </div>
      <div class="idx-middle-3">
        <span>东莞户籍党员 {{ getdatavalue(88) }} 人</span>
        <span>市外户籍 {{ getdatavalue(89) }} 人</span>
      </div>
      <div class="idx-middle-4">
        <div class="idx-middle-4L" id="dwjs"></div>
        <div class="idx-middle-4R">
          <div class="idx-middle-4Rbox">
            <span>{{ getdatavalue(90) }}</span>
            <span>预备党员</span>
          </div>
          <div class="idx-middle-4Rbox">
            <span>{{ getdatavalue(91) }}</span>
            <span>发展对象</span>
          </div>
          <div class="idx-middle-4Rbox">
            <span>{{ getdatavalue(92) }}</span>
            <span>入党积极分子</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "dwjs",
        dataTyp: "dwjs_dwjs",
      },
      queryParams1: {
        dataModule: "dwjs",
        dataTyp: "dwjs_dwjsnv",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      console.log(response);
      //    console.log(this.xdata);

      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "党务建设", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("dwjs"));
        let color = ["#0567E2", "#5B9BD4"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          calculable: false,
          color: color,
          // legend: {
          //   // 图例列表的布局朝向。 'horizontal'  'vertical'
          //   orient: "horizontal",
          //   left: "2%",
          //   top: "80%",
          //   itemWidth: 12,
          //   itemHeight: 12,
          //   textStyle: {
          //     //图例文字的样式
          //     color: "#fff",
          //     fontSize: 14,
          //     padding: 8,
          //   },
          //   formatter: function (name) {
          //     return "" + name;
          //   },
          //   data: ["女", "男"],
          // },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "25%",
              center: ["50%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  borderWidth: 5,
                  borderColor: "#071941",
                  label: {
                    show: true,
                    //   trigger: "item",
                    //   formatter: "{b} : {c}人",
                    formatter: "{b} :\n {c} \n ({d}%)",
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("dwjs"));
        let color = ["#0567E2", "#5B9BD4"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          calculable: false,
          color: color,
          // legend: {
          //   // 图例列表的布局朝向。 'horizontal'  'vertical'
          //   orient: "horizontal",
          //   left: "2%",
          //   top: "80%",
          //   itemWidth: 12,
          //   itemHeight: 12,
          //   textStyle: {
          //     //图例文字的样式
          //     color: "#fff",
          //     fontSize: 14,
          //     padding: 8,
          //   },
          //   formatter: function (name) {
          //     return "" + name;
          //   },
          //   data: ["女", "男"],
          // },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "50%",
              center: ["50%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  borderWidth: 5,
                  borderColor: "#071941",
                  label: {
                    show: true,
                    //   trigger: "item",
                    //   formatter: "{b} : {c}人",
                    formatter: "{b} : {c} \n ({d}%)",
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;

  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    .dqi-icon {
      vertical-align: middle;
      padding-right: 1vw;
    }
  }
  .idx-middle-2 {
    width: 100%;
    height: 7vh;
    // overflow: hidden;
    // border: 1px solid turquoise;
    .idx-Lbox-2 {
      width: 97%;
      height: 4vh;
      line-height: 4vh;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 2vh;
      display: inline-block;
      padding-left: 6vw;
      img {
        width: 1.1vw;
        height: 2vh;
      }
      .idx-dy-s1 {
        font-size: 1.3vw;
        font-family: hy;
        font-style: italic;
        font-weight: bold;
        color: #f9b129;
      }
      .dwjs-btn {
        width: 6vw;
        height: 4vh;
        // border: 1px solid teal;
        display: inline-block;
        position: relative;
        top: 0.5vh;
        cursor: pointer;
        margin-left: 1vw;
        .dwjs-btn-1 {
          width: 6vw;
          height: 4.2vh;
          margin-top: 1.5vh;
          position: absolute;
          top: -0.4vw;
          left: 0vw;
        }
        .dwjs-btn-2 {
          width: 1.8vw;
          height: 1.8vw;
          position: absolute;
          top: 1.3vh;
        }
        .dwjs-btn-3 {
          font-size: 0.8vw;
          position: absolute;
          left: 2vw;
          top: 1vh;
        }
      }
      span.idx-dy-sa1 {
        padding: 0 0.1vw;
      }
      .idx-dy-s2 {
        font-size: 1.3vw;
        font-family: hy;
        font-style: italic;
        font-weight: bold;
        color: #e54f2c;
      }
      .idx-dy-s3 {
        padding: 1vh 5.3vw 0 2vw;
        box-sizing: border-box;
        position: relative;
        .gl-sss {
          position: absolute;
          width: 1px;
          height: 2.2vh;
          background: #ffffff;
          top: 1.25vh;
          box-sizing: border-box;
          display: inline-block;
        }
      }
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    overflow: hidden;
    // border: 1px solid turquoise;
    font-size: 0.8vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #cacaca;
    padding-left: 2vw;
    span {
      &:first-child {
        padding-right: 1vw;
      }
    }
  }
  .idx-middle-4 {
    width: 100%;
    height: 27vh;
    .idx-middle-4L {
      width: 40%;
      height: 27vh;
      float: left;
    }
    .idx-middle-4R {
      width: 55%;
      height: 8vh;
      float: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 9vh;
      .idx-middle-4Rbox {
        width: 27%;
        height: 8vh;
        line-height: 3vh;
        background: #01689b;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        span {
          font-size: 0.9vw;
          &:first-child {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
