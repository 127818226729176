<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">志愿活动内容</div>
    <div class="idx-middle-2"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 30vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
  }
  .idx-middle-2 {
  }
}
</style>
