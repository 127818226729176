<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">
      <img class="dqi-icon" src="@/assets/image/danghui.png" alt="" />党支部情况
    </div>
    <div class="idx-middle-2" id="dzbu" @click="go"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ['dialog_click'],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "msjq",
        dataTyp: "msjq_msjqhz",
      },
      queryParams1: {
        dataModule: "dwjs",
        dataTyp: "dwjs_dzbqk",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
      
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      //    console.log(this.xdata);
      this.dataOptions1 = response.rows
      this.shijianFs();
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions1.map(item=>{
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName
        }
      })
      this.$emit("dialog_click","党支部情况",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("dzbu"));
        let color = ["#2363F1", "#24D8D7", "#807E00", "#FFDA08"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            orient: "vertical",
            left: "10%",
            top: "60%",
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 10,
              padding: 3,
            },
            data: this.xdata, //["社区党支部", "居住小区党支部", "企业党支部", "社会组织党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: ["18%", "25%"],
              center: ["50%", "30%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                  fontSize: 10,
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("dzbu"));
        let color = ["#2363F1", "#24D8D7", "#807E00", "#FFDA08"];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            orient: "vertical",
            left: "10%",
            top: "60%",
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 3,
            },
            data: this.xdata, //["社区党支部", "居住小区党支部", "企业党支部", "社会组织党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: ["30%", "40%"],
              center: ["50%", "30%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 51vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    .dqi-icon {
      vertical-align: middle;
      padding-right: 1vw;
    }
  }
  .idx-middle-2 {
    width: 100%;
    height: 45vh;
    // border: 1px solid tan;
  }
}
</style>
