<template>
  <div class="index-date-view">
    <div class="date">
      {{ year }}<span>年</span>{{ month }}<span>月</span>{{ day
      }}<span>日</span>
    </div>
    <div class="time">
      {{ time }}
    </div>
    <div class="week">{{ week }}</div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date";
export default {
  name: "date",
  data() {
    return {
      year: "",
      month: "",
      day: "",
      time: "",
      week: "",
      weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  created() {
    this.refresh();
    setInterval(this.refresh, 1000);
  },
  methods: {
    refresh() {
      let date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.month = this.month < 10 ? "0" + this.month : this.month;
      this.day = date.getDate();
      this.day = this.day < 10 ? "0" + this.day : this.day;
      this.time = formatDate(date, "hh:mm:ss");
      this.week = this.weeks[date.getDay()];
    },
  },
};
</script>

<style scoped lang="scss">
.index-date-view {
  width: 88%;
  height: 5.7vh;
  text-align: center;
  line-height: 5.7vh;
  color: #fff;
  font-size: 2.4vh;
  font-weight: bolder;
  /* opacity: 0.3; */
  /* background-color: #FF0000; */
  font-family: hy;
  margin-top: 1vh;
  text-align: left;
  padding-left: 3.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div,
  span {
    display: inline-block;
  }
  span {
    font-size: 1.8vh;
  }
  .date {
    width: 8.2vw;
    text-align: left;
    font-style: italic;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span {
      margin: 0 0.2vw;
      font-weight: 400;
      font-style: normal;
    }
  }
  .time {
    width: 5vw;
    text-align: left;
    font-style: italic;
  }
  .week {
    font-weight: 400;
    padding-left: 0.6vw;
  }
}
</style>
